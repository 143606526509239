<template>
	<div class="camera">
		<div>
			<div
				@click="
					$router.push({
						path: '/SmartClassroom/RoomList',
						query: { equipment: 'camera' },
					})
				"
				class="toggle-class"
			>
				<span>{{ roomName }}</span>
				<van-icon name="arrow-down" />
			</div>
			<div>请勾选需要查看的摄像头</div>
			<div>
				<van-checkbox-group v-model="radio">
					<van-grid :border="false" :column-num="2">
						<van-grid-item>
							<div class="camera-radio">
								<van-checkbox @click="toggle" name="1" shape="square"
									>一楼办公室摄像头</van-checkbox
								>
							</div>
						</van-grid-item>
						<van-grid-item>
							<div class="camera-radio">
								<van-checkbox @click="toggle" name="2" shape="square"
									>一楼走廊摄像头</van-checkbox
								>
							</div>
						</van-grid-item>
						<van-grid-item>
							<div class="camera-radio">
								<van-checkbox @click="toggle" name="3" shape="square"
									>一楼展厅摄像头</van-checkbox
								>
							</div>
						</van-grid-item>
						<van-grid-item>
							<div class="camera-radio">
								<van-checkbox @click="toggle" name="4" shape="square"
									>一楼客厅摄像头</van-checkbox
								>
							</div>
						</van-grid-item>
					</van-grid>
				</van-checkbox-group>
			</div>
		</div>
		<div>
			<div :class="defineClass">
				<div
					v-for="(item,i) in radio"
					:key="i"
					class="grid-item"
					:class="{ 'grid-horizontal': fullscreen }"
				>
					<div >
						<LivePlayer videoUrl=""  :ref="'a'+i"   live />
					</div>
				</div>
			</div>
		</div>
		<div @click="showMenu = !showMenu" class="return-btn">
			<i class="iconfont icon-control04_menu"></i>
		</div>
		<van-popup
			v-model="showMenu"
			position="bottom"
			round
			class="return-menu"
			style="height:150px"
		>
			<van-grid clickable :column-num="3">
				<van-grid-item @click="$router.go(-1)">
					<div class="rm-item">
						<div><i class="iconfont icon-control_arrow"></i></div>
						<div>返回上一页</div>
					</div>
				</van-grid-item>
				<van-grid-item to="/SmartClassroom/Index">
					<div class="rm-item">
						<div><i class="iconfont icon-shouye_home01"></i></div>
						<div>设备首页</div>
					</div>
				</van-grid-item>
			</van-grid>
			<div @click="showMenu = false" class="popup-close">关闭</div>
		</van-popup>
	</div>
</template>
<script>
import { CheckboxGroup, Checkbox, Grid, GridItem, Image as VanImage, Popup } from 'vant'
import { getDefaultRoom } from '@/api/TerminalEquipment/RoomEquipment.js'
import LivePlayer from '@liveqing/liveplayer'
export default {
	components: {
		[CheckboxGroup.name]: CheckboxGroup,
		[Checkbox.name]: Checkbox,
		[Grid.name]: Grid,
		[Popup.name]: Popup,
		[GridItem.name]: GridItem,
		[VanImage.name]: VanImage,
		LivePlayer
	},
	data() {
		return {
			checked: true,
			roomId: 0, //房间ID
			roomName: '', //房间名称
			radio: ['1', '2'],
			showMenu: false,
			num: 1,
			defineClass: 'grid-first',
			fullscreen: false,
			player: '',
			player1: '',
			player2: '',
			player3: '',
			url: 'http://127.0.0.1:10080/fvod/PnCsnxdMg/video.m3u8',
			url1: 'http://127.0.0.1:10080/fvod/PnCsnxdMg/video.m3u8',
			url2: 'http://127.0.0.1:10080/fvod/PnCsnxdMg/video.m3u8',
			url3: 'http://127.0.0.1:10080/fvod/PnCsnxdMg/video.m3u8',
			arryVideo:[
				{i:1,player:'',url:'ws://47.112.255.159:70/rtp/1D42856D.flv',url2:'http://47.112.255.159:70/rtp/1D42856D/hls.m3u8'},
				{i:2,player:'',url:'ws://47.112.255.159:70/rtp/3869139F.flv',url2:'http://47.112.255.159:70/rtp/3869139F/hls.m3u8'},
				{i:3,player:'',url:'ws://47.112.255.159:70/rtp/2251C92D.flv',url2:'http://47.112.255.159:70/rtp/2251C92D/hls.m3u8'},
				{i:4,player:'',url:'',url2:''}
				]
		}
	},
	methods: {
		toggle() {
			// debugger
			
			console.log(this.radio)
			this.radio.length === 4
				? (this.defineClass = 'grid-third')
				: this.radio.length === 3
				? (this.defineClass = 'grid-second')
				: (this.defineClass = 'grid-first')

			this.playVideo();
		},

		callbackfun(e) {
			console.log('callbackfun', e)
		},
		videoUrlPlay(item,index){
			// this.$nextTick(()=>{
			// 	this.$set(this.$refs.a0,'videoUrl','ws://47.112.255.159:70/rtp/1D42856D.flv');
			// 		// this.$refs.a0.videoUrl='ws://47.112.255.159:70/rtp/1D42856D.flv';
			// this.$refs.a0.play();
			// })
			
			// setTimeout(() => {
			// 	this.$set(this.$refs.a0,'videoUrl','ws://47.112.255.159:70/rtp/1D42856D.flv');
			// 		// this.$refs.a0.videoUrl='ws://47.112.255.159:70/rtp/1D42856D.flv';
			// this.$refs.a0.play();
			// console.log("videoUrl",this.$refs.a0.videoUrl);
			// }, 2000);
			// debugger
		
			// console.log("refs",this.$refs.a0[0]);
			let result=this.arryVideo[item]
			switch (index) {
				case 0:
					if(this.defineMethods.isIOS()){
						this.$nextTick(()=>{
							this.$set(this.$refs.a0[0],'videoUrl',result.url2);
								// this.$refs.a0.videoUrl='ws://47.112.255.159:70/rtp/1D42856D.flv';
							this.$refs.a0[0].play();
						})
					}else{
						this.$nextTick(()=>{
							this.$set(this.$refs.a0[0],'videoUrl',result.url);
								// this.$refs.a0.videoUrl='ws://47.112.255.159:70/rtp/1D42856D.flv';
							this.$refs.a0[0].play();
						})
					}
					break;
				case 1:
					if(this.defineMethods.isIOS()){
						this.$nextTick(()=>{
							this.$set(this.$refs.a1[0],'videoUrl',result.url2);
								// this.$refs.a0.videoUrl='ws://47.112.255.159:70/rtp/1D42856D.flv';
							this.$refs.a1[0].play();
						})
					}else{
						this.$nextTick(()=>{
							this.$set(this.$refs.a1[0],'videoUrl',result.url);
								// this.$refs.a0.videoUrl='ws://47.112.255.159:70/rtp/1D42856D.flv';
							this.$refs.a1[0].play();
						})
					}
					break;
				case 2:
					if(this.defineMethods.isIOS()){
						this.$nextTick(()=>{
							this.$set(this.$refs.a2[0],'videoUrl',result.url2);
								// this.$refs.a0.videoUrl='ws://47.112.255.159:70/rtp/1D42856D.flv';
							this.$refs.a2[0].play();
						})
					}else{
						this.$nextTick(()=>{
							this.$set(this.$refs.a2[0],'videoUrl',result.url);
								// this.$refs.a0.videoUrl='ws://47.112.255.159:70/rtp/1D42856D.flv';
							this.$refs.a2[0].play();
						})
					}
					break;
				case 3:
					if(this.defineMethods.isIOS()){
						this.$nextTick(()=>{
							this.$set(this.$refs.a3[0],'videoUrl',result.url2);
								// this.$refs.a0.videoUrl='ws://47.112.255.159:70/rtp/1D42856D.flv';
							this.$refs.a3[0].play();
						})
					}else{
						this.$nextTick(()=>{
							this.$set(this.$refs.a3[0],'videoUrl',result.url);
								// this.$refs.a0.videoUrl='ws://47.112.255.159:70/rtp/1D42856D.flv';
							this.$refs.a3[0].play();
						})
					}
					break;
				default:
					break;
			}
		},
		playVideo(){
			let that=this;
			// 	// debugger
			if(this.radio.length>0){
				this.radio.forEach((item,index)=>{
					switch (item) {
						case '1':
							that.videoUrlPlay(0,index);
							break;
						case '2':
							that.videoUrlPlay(1,index);
							break;
						case '3':
							that.videoUrlPlay(2,index);
							break;
						case '4':
							that.videoUrlPlay(3,index);
							break;
						default:
							break;
					}
				})
			}
		}
		// playVideo(){
		// 	let that=this;
		// 	// debugger
		// if(this.radio.length>0){
		// 	this.radio.forEach((item,index)=>{
		// 		if(item==='1'){
		// 			debugger
		// 			let result=that.arryVideo[0]
		// 			if(result.player===''){
						
		// 				// 实例化播放器
		// 				result.player = new WasmPlayer(null, 'a'+index, this.callbackfun, {
		// 					// Height: true,
		// 					openAudio: false,
		// 					HideKbs: true,
		// 				})
		// 			}else{
		// 				if(result.player.playerInstance!=null){
		// 					result.player.destroy();
		// 				}
		// 					result.player = new WasmPlayer(null, 'a'+index, this.callbackfun, {
		// 					// Height: true,
		// 					openAudio: false,
		// 					HideKbs: true,
		// 				})
		// 			}
		// 			// result.player.pause();
		// 			// 调用播放
		// 			// result.player.endLoading();
		// 			// result.player.startLoading();
		// 			if(result.player.playerInstance==null){
		// 				if(this.defineMethods.isIOS()){
		// 					result.player.play(result.url2, 1)
		// 				}else{
		// 					result.player.play(result.url, 1)
		// 				}
		// 			}
		// 		}
		// 		if(item==='2'){
		// 			let result=that.arryVideo[1]
		// 			if(result.player===''){
		// 				// 实例化播放器
		// 				result.player = new WasmPlayer(null, 'a'+index, this.callbackfun, {
		// 					// Height: true,
		// 					openAudio: false,
		// 					HideKbs: true,
		// 				})
		// 			}else{
		// 				if(result.player.playerInstance!=null){
		// 					result.player.destroy();
		// 				}
		// 				result.player = new WasmPlayer(null, 'a'+index, this.callbackfun, {
		// 					// Height: true,
		// 					openAudio: false,
		// 					HideKbs: true,
		// 				})
		// 			}
		// 			// result.player.pause();
		// 			// 调用播放
		// 			// result.player.endLoading();
		// 			// result.player.startLoading();
		// 			if(result.player.playerInstance==null){
		// 				if(this.defineMethods.isIOS()){
		// 					result.player.play(result.url2, 1)
		// 				}else{
		// 					result.player.play(result.url, 1)
		// 				}
		// 			}
		// 		}
		// 		if(item==='3'){
		// 			let result=that.arryVideo[2]
		// 			if(result.player===''){
						
		// 				// 实例化播放器
		// 				result.player = new WasmPlayer(null, 'a'+index, this.callbackfun, {
		// 					// Height: true,
		// 					openAudio: false,
		// 					HideKbs: true,
		// 				})
		// 			}else{
		// 				if(result.player.playerInstance!=null){
		// 					result.player.destroy();
		// 				}
		// 					result.player = new WasmPlayer(null, 'a'+index, this.callbackfun, {
		// 					// Height: true,
		// 					openAudio: false,
		// 					HideKbs: true,
		// 				})
		// 			}
		// 			// result.player.pause();
		// 			// 调用播放
		// 			// result.player.endLoading();
		// 			// result.player.startLoading();
		// 			if(result.player.playerInstance==null){
		// 				if(this.defineMethods.isIOS()){
		// 					result.player.play(result.url2, 1)
		// 				}else{
		// 					result.player.play(result.url, 1)
		// 				}
		// 			}
		// 		}
		// 		if(item==='4'){
		// 			let result=that.arryVideo[3]
		// 			if(result.player===''){
						
		// 				// 实例化播放器
		// 				result.player = new WasmPlayer(null, 'a'+index, this.callbackfun, {
		// 					// Height: true,
		// 					openAudio: false,
		// 					HideKbs: true,
		// 				})
		// 			}else{
		// 				if(result.player.playerInstance!=null){
		// 					result.player.destroy();
		// 				}
		// 					result.player = new WasmPlayer(null, 'a'+index, this.callbackfun, {
		// 					// Height: true,
		// 					openAudio: false,
		// 					HideKbs: true,
		// 				})
		// 			}
		// 			// result.player.pause();
		// 			// 调用播放
		// 			// result.player.endLoading();
		// 			// result.player.startLoading();
		// 			if(result.player.playerInstance==null){
		// 				if(this.defineMethods.isIOS()){
		// 					result.player.play(result.url2, 1)
		// 				}else{
		// 					result.player.play(result.url, 1)
		// 				}
		// 			}
		// 		}
		// 	})
		// }
		// }
	},
	computed: {},
	async mounted() {
		if (this.$route.query.roomId == -1) {
			let res = await getDefaultRoom()
			this.roomId = res.Data.id
			this.roomName = res.Data.RoomNmae
		} else {
			let info = JSON.parse(localStorage.RoomInfo)
			this.roomId = info.RoomId
			this.roomName = info.RoomNmae
		}
		this.playVideo();
	}
}
</script>
<style lang="scss" scoped>
@import '../../../style/public.scss';
.camera {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	background: $bgColor;
	position: relative;

	> div:first-child {
		padding-bottom: 12px;
		background: #fff;
		> div:nth-child(2) {
			margin-left: 24px;
			font-size: 13px;
			font-weight: 400;
			color: #999999;
			margin-top: 16px;
		}
		> div:last-child {
			margin-left: 24px;
			margin-top: 12px;
		}
	}
	> div:nth-child(2) {
		margin-top: 20px;
		height: calc(100% - 170px);
		overflow-y: scroll;
	}
	.camera-radio {
		width: 100%;
		text-align: left;
	}

	.grid-first {
		display: grid;
		grid-template-columns: repeat(1, 100%);
	}
	.grid-second {
		display: grid;
		grid-template-columns: repeat(2, 50%);
	}
	.grid-third {
		display: grid;
		grid-template-columns: repeat(2, 50%);
	}
	.grid-second > div:first-child {
		grid-column-start: 1;
		grid-column-end: 3;
	}

	.grid-item {
		width: 343px;
		height: 210px;
		position: relative;
		> div {
			width: 100%;
			height: 100%;
		}
	}
	.grid-first > div {
		margin: 8px 16px;
	}
	.grid-second > div:first-child {
		margin: 8px 16px;
	}
	.grid-second > div:not(:first-child) {
		width: 166px;
	}
	.grid-second > div:not(:first-child):nth-child(odd) {
		margin: 8px;
		margin-right: 16px;
	}
	.grid-second > div:not(:first-child):nth-child(even) {
		margin: 8px;
		margin-left: 16px;
	}
	.grid-third > div {
		width: 166px;
		height: 210px;
	}
	.grid-third > div:nth-child(odd) {
		margin: 8px;
		margin-left: 16px;
	}
	.grid-third > div:nth-child(even) {
		margin: 8px;
		margin-right: 16px;
	}

	.return-btn {
		position: fixed;
		top: 543px;
		right: 10px;
		width: 40px;
		text-align: center;
		height: 40px;
		line-height: 40px;
		background: #ffffff;
		box-shadow: 0px 0px 6px #c8c8c8;
		border-radius: 50%;
		color: #949494;
		i {
			font-size: 24px;
		}
	}
	.return-menu {
		.rm-item {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			text-align: center;
			height: 80px;
			font-size: 12px;
			font-weight: 400;
			color: $titleColor;
			i {
				font-size: 28px;
			}
			> div:first-child {
				margin-bottom: 5px;
			}
		}
		margin-top: 20px;
		.popup-close {
			height: 38px;
			line-height: 38px;
			text-align: center;
			font-size: 17px;
			font-weight: 400;
			color: $titleColor;
			border-top: 1px solid #ebedf0;
		}
	}
}
</style>
<style lang="scss">
@import '../../../style/public.scss';
.camera {
	.video-wrapper{
		height: 100%!important;
	}
	.player-wrapper{
		height: 100%!important;
	}
	.vjs-live-control.vjs-control{
		display: none!important;;
	}
	.video-js.vjs-control{
		display: none!important;;
	}
	.iconqingxiLOGO:before{
		content:""
	}
	.van-checkbox__icon {
		.van-icon {
			border-radius: 5px;
		}
	}
	.van-checkbox__icon--checked {
		.van-icon {
			color: $themeColor;
			background-color: #fff;
			border-color: $themeColor;
		}
	}
	.van-grid-item__content {
		padding: 4px 0;
	}
}
</style>
